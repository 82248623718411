/* src/App.css */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  background-color: #333;
  overflow: hidden;
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navbar-list li {
  display: inline;
  margin: 0 15px;
}

.navbar-list li a {
  color: white;
  text-decoration: none;
  padding: 14px 20px;
  display: block;
  transition: background-color 0.3s;
}

.navbar-list li a:hover {
  background-color: #575757;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.contact-info p {
  margin: 5px 0;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
