/* src/components/Home.css */
.home-container {
    text-align: center;
    padding: 20px;
  }
  
  .images-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .profile-image {
    width: 200px;
    height: auto;
    border-radius: 10px;
  }
  
  .resume-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .resume-link:hover {
    background-color: #0056b3;
  }
  
  .highlights-container {
    margin-top: 40px;
    text-align: left;
  }
  
  .highlight {
    background-color: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .highlight h3 {
    margin-top: 0;
  }
  
  .highlight a {
    color: #007bff;
    text-decoration: none;
  }
  
  .highlight a:hover {
    text-decoration: underline;
  }
  
  .important {
    font-weight: bold;
    text-decoration: underline;
  }
  