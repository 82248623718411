/* src/components/Achievements.css */
.achievements-container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .achievement-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .achievement-preview iframe {
    width: 100%; /* Set to full width */
    max-width: 2000px; /* Set a maximum width similar to the container */
    height: 500px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .achievement-links {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
    margin-top: 20px;
  }
  
  .achievement-links li {
    margin-bottom: 10px;
  }
  
  .achievement-links a {
    color: #007bff;
    text-decoration: none;
    font-size: 1.1em;
  }
  
  .achievement-links a:hover {
    text-decoration: underline;
  }
  